<template>
  <Form
    @submit="onLogin"
    :validation-schema="{
      email: 'email',
      password: 'required',
    }"
  >
    <div class="mb-6">
      <div class="relative w-full flex flex-wrap items-stretch">
        <span
          class="
            absolute
            z-10
            py-3
            pl-3
            w-8
            h-full
            leading-snug
            bg-transparent
            rounded
            text-base
            font-normal
            text-gray-400 text-center
            flex
            items-center
            justify-center
          "
        >
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.1889 12.4936C12.8521 11.6957 12.3632 10.9709 11.7497 10.3596C11.1379 9.74661 10.4133 9.25785 9.61573 8.92034C9.60858 8.91677 9.60144 8.91499 9.5943 8.91141C10.7068 8.10784 11.43 6.79891 11.43 5.32213C11.43 2.8757 9.44787 0.893555 7.00144 0.893555C4.55501 0.893555 2.57287 2.8757 2.57287 5.32213C2.57287 6.79891 3.29608 8.10784 4.40858 8.9132C4.40144 8.91677 4.3943 8.91856 4.38715 8.92213C3.58715 9.25963 2.8693 9.74356 2.25323 10.3614C1.64021 10.9731 1.15145 11.6978 0.81394 12.4953C0.482372 13.2762 0.30355 14.1133 0.287154 14.9614C0.286677 14.9805 0.29002 14.9994 0.296986 15.0172C0.303951 15.0349 0.314399 15.0511 0.327712 15.0647C0.341025 15.0784 0.356935 15.0892 0.374504 15.0966C0.392074 15.104 0.410947 15.1079 0.430011 15.1078H1.50144C1.58001 15.1078 1.64251 15.0453 1.6443 14.9686C1.68001 13.59 2.23358 12.2989 3.21215 11.3203C4.22465 10.3078 5.5693 9.7507 7.00144 9.7507C8.43358 9.7507 9.77822 10.3078 10.7907 11.3203C11.7693 12.2989 12.3229 13.59 12.3586 14.9686C12.3604 15.0471 12.4229 15.1078 12.5014 15.1078H13.5729C13.5919 15.1079 13.6108 15.104 13.6284 15.0966C13.6459 15.0892 13.6619 15.0784 13.6752 15.0647C13.6885 15.0511 13.6989 15.0349 13.7059 15.0172C13.7129 14.9994 13.7162 14.9805 13.7157 14.9614C13.6979 14.1078 13.5211 13.2775 13.1889 12.4936ZM7.00144 8.39356C6.1818 8.39356 5.41037 8.07391 4.83001 7.49356C4.24965 6.9132 3.93001 6.14177 3.93001 5.32213C3.93001 4.50248 4.24965 3.73106 4.83001 3.1507C5.41037 2.57034 6.1818 2.2507 7.00144 2.2507C7.82108 2.2507 8.59251 2.57034 9.17287 3.1507C9.75322 3.73106 10.0729 4.50248 10.0729 5.32213C10.0729 6.14177 9.75322 6.9132 9.17287 7.49356C8.59251 8.07391 7.82108 8.39356 7.00144 8.39356Z"
              fill="#0057FF"
            />
          </svg>
        </span>
        <Field v-model="email" name="email" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            autocomplete="username"
            class="
              relative
              py-3
              px-2
              pl-10
              w-full
              bg-white
              rounded-lg
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Usuario:"
          />
        </Field>
      </div>
      <div class="text-left">
        <ErrorMessage name="email" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <span
          class="
            absolute
            z-10
            py-3
            pl-3
            w-8
            h-full
            leading-snug
            bg-transparent
            rounded
            text-base
            font-normal
            text-gray-400 text-center
            flex
            items-center
            justify-center
          "
        >
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7168 7.14802H11.5025V3.14802C11.5025 1.88552 10.4793 0.862305 9.2168 0.862305H4.78823C3.52573 0.862305 2.50251 1.88552 2.50251 3.14802V7.14802H1.28823C0.972154 7.14802 0.716797 7.40338 0.716797 7.71945V14.5766C0.716797 14.8927 0.972154 15.148 1.28823 15.148H12.7168C13.0329 15.148 13.2882 14.8927 13.2882 14.5766V7.71945C13.2882 7.40338 13.0329 7.14802 12.7168 7.14802ZM3.78823 3.14802C3.78823 2.59623 4.23644 2.14802 4.78823 2.14802H9.2168C9.76858 2.14802 10.2168 2.59623 10.2168 3.14802V7.14802H3.78823V3.14802ZM12.0025 13.8623H2.00251V8.43373H12.0025V13.8623ZM6.50251 11.3802V12.3266C6.50251 12.4052 6.5668 12.4694 6.64537 12.4694H7.35965C7.43823 12.4694 7.50251 12.4052 7.50251 12.3266V11.3802C7.64992 11.2743 7.75994 11.1245 7.81675 10.9521C7.87355 10.7798 7.87421 10.5939 7.81862 10.4211C7.76303 10.2484 7.65407 10.0977 7.50741 9.99087C7.36076 9.884 7.18397 9.82642 7.00251 9.82642C6.82105 9.82642 6.64427 9.884 6.49761 9.99087C6.35096 10.0977 6.24199 10.2484 6.18641 10.4211C6.13082 10.5939 6.13147 10.7798 6.18828 10.9521C6.24508 11.1245 6.35511 11.2743 6.50251 11.3802Z"
              fill="#0057FF"
            />
          </svg>
        </span>
        <Field v-model="password" name="password" v-slot="{ field }">
          <input
            v-bind="field"
            type="password"
            autocomplete="current-password"
            class="
              relative
              py-3
              px-2
              pl-10
              w-full
              bg-white
              rounded-lg
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Contraseña:"
          />
        </Field>
      </div>
      <div class="text-left">
        <ErrorMessage name="password" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="py-2 mb-2 text-left">
      <input
        v-model="rememberMe"
        type="checkbox"
        class="checked:bg-primary checked:border-transparent cursor-pointer ..."
      />
      <span class="select-none"> Recordarme </span>
    </div>
    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div>
      <button
        class="rounded bg-primary py-2 px-4 text-white w-full"
        type="submit"
      >
        Ingresar
      </button>
    </div>
  </Form>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useField } from "vee-validate";

const store = useStore();
const router = useRouter();

const { value: email } = useField("email");
const { value: password } = useField("password");
const rememberMe = ref(Boolean(localStorage.getItem("remember_me")));
let responseType = ref(null);
let responseMessage = ref(null);

email.value = localStorage.getItem("remember_me_email");

const onLogin = async () => {
  try {
    responseType.value = null;
    responseMessage.value = null;

    await store.dispatch("login", {
      email: email.value,
      password: password.value,
    });

    if (rememberMe.value) {
      localStorage.setItem("remember_me", true);
      localStorage.setItem("remember_me_email", email.value);
    } else {
      localStorage.removeItem("remember_me");
      localStorage.removeItem("remember_me_email");
    }

    router.push({ name: "home" });
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al iniciar sesión";

    console.error(error);
  }
};
</script>
